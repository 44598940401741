.t {
  transform: none;
}
[news-main] {
  padding-top: 3.75rem;
  padding-bottom: 8.75rem;
}
[news-main] section h3 {
  font-size: 1.5rem;
}
[news-main] section.hero {
  position: relative;
  max-width: 167.5rem;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
}
[news-main] section.hero a {
  display: block;
}
[news-main] section.hero .kv-wrap {
  width: 100%;
  aspect-ratio: 0.75;
  position: relative;
}
[news-main] section.hero .kv-wrap img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
}
[news-main] section.hero .info {
  position: absolute;
  left: 0rem;
  bottom: 0rem;
  right: auto;
  top: auto;
  padding-top: 2rem;
  padding-right: 1.125rem;
  padding-bottom: 2rem;
  padding-left: 1.125rem;
  color: #fff;
}
[news-main] section.hero .info p {
  font-size: 0.75rem;
  padding-bottom: 0.625rem;
}
[news-main] section.hero .info p span {
  margin-left: 0.75rem;
  font-weight: 500;
}
[news-main] section.hero .info h2 {
  font-size: 1.25rem;
  line-height: 0.09375rem;
  letter-spacing: -0.015625rem;
  line-height: 1.5;
  font-weight: 700;
}
[news-main] section.featured {
  padding-left: 1.125rem;
  padding-right: 1.125rem;
  margin-top: 6rem;
  position: relative;
  max-width: 167.5rem;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  overflow-x: hidden;
  box-sizing: border-box;
}
[news-main] section.featured h3 {
  padding-bottom: 1.5rem;
}
[news-main] section.featured .swiper-container {
  overflow: visible;
}
[news-main] section.featured .swiper-container .swiper-wrapper .swiper-slide {
  width: 100%;
  height: 16.6875rem;
  max-width: 20.3125rem;
}
[news-main] section.featured .swiper-container .swiper-wrapper .swiper-slide:last-of-type .slider-item {
  padding: 0;
}
[news-main] section.featured .swiper-container .swiper-wrapper .swiper-slide .slider-item {
  width: 100%;
  height: 100%;
  padding-right: 0.75rem;
  box-sizing: border-box;
  cursor: pointer;
}
[news-main] section.featured .swiper-container .swiper-wrapper .swiper-slide .slider-item .inner {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
[news-main] section.featured .swiper-container .swiper-wrapper .swiper-slide .slider-item .inner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: scale(1);
  transition: transform 1s cubic-bezier(0, 0, 0.13, 1.04);
}
[news-main] section.featured .swiper-container .swiper-wrapper .swiper-slide .slider-item .inner p {
  position: absolute;
  text-transform: uppercase;
  z-index: 9;
  left: 50%;
  top: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  font-family: 'Helvetica-Now-Display-Black', sans-serif;
  color: #fff;
  font-size: 2.375rem;
  line-height: 0.060625rem;
  line-height: 0.97;
  text-align: center;
}
.no-touch [news-main] section.featured .swiper-container .swiper-wrapper .swiper-slide .slider-item .inner:hover img {
  transform: scale(1.1);
}
[news-main] section.featured .swiper-scrollbar {
  width: 100%;
  height: 0.125rem;
  background-color: #ddd;
  margin-top: 2.5rem;
}
[news-main] section.featured .swiper-scrollbar .swiper-scrollbar-drag {
  background-color: #333;
}
[news-main] section.featured .swiper-navigation {
  display: none;
}
[news-main] section.latest {
  padding-left: 1.125rem;
  padding-right: 1.125rem;
  margin-top: 6rem;
  position: relative;
  max-width: 167.5rem;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  text-align: center;
}
[news-main] section.latest h3 {
  padding-bottom: 1.5rem;
  text-align: left;
}
[news-main] section.latest ul {
  display: grid;
  grid-template-columns: 1fr;
}
[news-main] section.latest ul li {
  margin-bottom: 2.5rem;
  text-align: left;
}
[news-main] section.latest ul li:last-of-type {
  margin-bottom: 0rem;
}
.no-touch [news-main] section.latest ul li:hover .img-wrap {
  position: relative;
}
.no-touch [news-main] section.latest ul li:hover .img-wrap img {
  transform: scale(1.1);
  position: relative;
}
[news-main] section.latest ul li .img-wrap {
  margin-bottom: 1.25rem;
  width: 100%;
  aspect-ratio: 1.88888889;
  overflow: hidden;
  position: relative;
}
[news-main] section.latest ul li .img-wrap img {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: scale(1);
  transition: transform 1s cubic-bezier(0, 0, 0.13, 1.04);
}
[news-main] section.latest ul li p {
  color: #333;
}
[news-main] section.latest ul li p:first-of-type {
  font-size: 0.75rem;
  padding-bottom: 0.625rem;
}
[news-main] section.latest ul li p:first-of-type span {
  margin-left: 0.75rem;
  display: inline-block;
  font-weight: 500;
}
[news-main] section.latest ul li p:last-of-type {
  font-size: 1.125rem;
  line-height: 0.100625rem;
  letter-spacing: -0.015625rem;
  line-height: 1.61;
  font-weight: 700;
}
[news-main] section.latest .more-btn {
  text-transform: uppercase;
  position: relative;
  font-size: 1rem;
  line-height: 0.08625rem;
  line-height: 1.38;
  font-weight: 700;
  color: #333;
  display: inline-block;
  margin-top: 4.5rem;
}
[news-main] section.latest .more-btn p {
  display: inline-block;
  vertical-align: middle;
}
[news-main] section.latest .more-btn p.icon {
  width: 1.125rem;
  height: 1.125rem;
  margin-left: 0.5rem;
  position: relative;
  overflow: hidden;
  transform: translateX(-10%);
  transition: transform 0.6s ease;
}
[news-main] section.latest .more-btn p.icon span {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0rem;
  top: 0rem;
  right: auto;
  bottom: auto;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('/img/common/btn-icon-landing.png');
}
[news-main] section.latest .more-btn:after {
  content: '';
  position: absolute;
  width: 0rem;
  height: 0.125rem;
  background-color: #333;
  left: 0rem;
  bottom: -0.3125rem;
  right: auto;
  top: auto;
  transition: width 0.6s cubic-bezier(0.4, -0.03, 0, 1.03);
}
.no-touch [news-main] section.latest .more-btn:hover:after {
  width: 100%;
}
.no-touch [news-main] section.latest .more-btn:hover p.icon {
  transform: translateX(20%);
}
[news-main] section.categories {
  padding-left: 1.125rem;
  padding-right: 1.125rem;
  margin-top: 8.25rem;
  position: relative;
  max-width: 167.5rem;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
}
[news-main] section.categories h3 {
  padding-bottom: 2rem;
}
[news-main] section.categories ul li {
  margin-right: 2rem;
  display: inline-block;
}
[news-main] section.categories ul li:last-of-type {
  margin-right: 0rem;
}
[news-main] section.categories ul li a {
  font-family: 'Helvetica-Now-Display-Black', sans-serif;
  -webkit-text-stroke: 1px #1e1e1e;
  color: #fff;
  font-size: 3.125rem;
  line-height: 4.125rem;
  transition: color 0.4s ease;
}
.no-touch [news-main] section.categories ul li a:hover {
  color: #1e1e1e;
}
@media (min-width: 768px) {
  [news-main] {
    padding-bottom: 10rem;
  }
  [news-main] section.hero {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }
  [news-main] section.hero .kv-wrap {
    aspect-ratio: 1.77777778;
  }
  [news-main] section.hero .info {
    padding: 1.5rem;
    left: 2.25rem;
    width: 31.125rem;
  }
  [news-main] section.featured {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }
  [news-main] section.featured .swiper-container .swiper-wrapper .swiper-slide {
    width: 25.4375rem;
    height: 20.0625rem;
    max-width: none;
  }
  [news-main] section.featured .swiper-container .swiper-wrapper .swiper-slide .slider-item {
    padding-right: 1rem;
  }
  [news-main] section.featured .swiper-container .swiper-wrapper .swiper-slide .slider-item .inner p {
    font-size: 2.875rem;
    line-height: 0.06125rem;
    line-height: 0.98;
  }
  [news-main] section.featured .swiper-scrollbar {
    display: none;
  }
  [news-main] section.featured .swiper-navigation {
    display: block;
    position: absolute;
    right: 2.25rem;
    top: 0.5rem;
    left: auto;
    bottom: auto;
  }
  [news-main] section.featured .swiper-navigation a {
    position: relative;
    width: 1.5rem;
    height: 1.5rem;
    display: inline-block;
    vertical-align: top;
    left: 0rem;
    top: 0rem;
    right: auto;
    bottom: auto;
    transform: translateX(0rem) translateY(0rem);
    margin: 0;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: transparent;
    color: inherit;
  }
  .no-touch [news-main] section.featured .swiper-navigation a:hover {
    opacity: 0.6;
  }
  [news-main] section.featured .swiper-navigation a:after {
    display: none;
  }
  [news-main] section.featured .swiper-navigation .swiper-button-prev {
    margin-right: 1.25rem;
    background-image: url('/img/common/ic-swipe-prev-dark.png');
  }
  [news-main] section.featured .swiper-navigation .swiper-button-next {
    background-image: url('/img/common/ic-swipe-next-dark.png');
  }
  [news-main] section.latest {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }
  [news-main] section.latest ul {
    grid-template-columns: 1fr 1fr;
    column-gap: 18px;
  }
  [news-main] section.latest ul li {
    margin-bottom: 3.5rem;
  }
  [news-main] section.latest ul li:nth-last-child(2) {
    margin-bottom: 0rem;
  }
  [news-main] section.categories {
    margin-top: 8.25rem;
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }
  [news-main] section.categories h3 {
    padding-bottom: 5.25rem;
  }
  [news-main] section.categories ul li {
    margin-right: 2.25rem;
  }
  [news-main] section.categories ul li a {
    font-size: 4.25rem;
    line-height: 5.25rem;
    
  }
}
@media (min-width: 1024px) {
  [news-main] {
    padding-bottom: 12.5rem;
  }
  [news-main] section.hero .info {
    padding: 1.875rem;
    width: 37.5rem;
  }
  [news-main] section.hero .info p {
    font-size: 0.75rem;
    padding-bottom: 0.875rem;
  }
  [news-main] section.hero .info h2 {
    font-size: 1.5rem;
    line-height: 0.09375rem;
    letter-spacing: -0.015625rem;
    line-height: 1.5;
  }
  [news-main] section.featured {
    margin-top: 6.75rem;
  }
  [news-main] section.featured .swiper-container .swiper-wrapper .swiper-slide {
    width: 29.75rem;
    height: 23.625rem;
  }
  [news-main] section.featured .swiper-container .swiper-wrapper .swiper-slide .slider-item .inner p {
    font-size: 3.375rem;
    line-height: 0.06125rem;
    line-height: 0.98;
  }
  [news-main] section.latest {
    margin-top: 6.75rem;
  }
  [news-main] section.latest ul {
    text-align: left;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 14px;
  }
  [news-main] section.latest ul li {
    margin-bottom: 4.5rem;
  }
  [news-main] section.latest ul li:nth-last-child(3) {
    margin-bottom: 0rem;
  }
  [news-main] section.latest .more-btn {
    margin-top: 6rem;
  }
  [news-main] section.categories {
    margin-top: 8.25rem;
  }
  [news-main] section.categories h3 {
    padding-bottom: 3rem;
  }
  [news-main] section.categories ul li {
    margin-right: 3.5rem;
  }
  [news-main] section.categories ul li a {
    font-size: 5.5rem;
    line-height: 6.625rem;
    
  }
}
@media (min-width: 1440px) {
  [news-main] {
    padding-top: 5rem;
    padding-bottom: 16.25rem;
  }
  [news-main] section h3 {
    font-size: 1.625rem;
  }
  [news-main] section.hero {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  [news-main] section.hero .info {
    padding: 3rem;
    width: 56.25rem;
    left: 3rem;
  }
  [news-main] section.hero .info p {
    font-size: 0.75rem;
    padding-bottom: 0.875rem;
  }
  [news-main] section.hero .info h2 {
    font-size: 2.125rem;
    line-height: 0.09625rem;
    letter-spacing: -0.015625rem;
    line-height: 1.54;
  }
  [news-main] section.featured {
    padding-left: 3rem;
    padding-right: 3rem;
    margin-top: 10rem;
  }
  [news-main] section.featured h3 {
    padding-bottom: 2rem;
  }
  [news-main] section.featured .swiper-container .swiper-wrapper .swiper-slide {
    width: 42.625rem;
    height: 33.3125rem;
  }
  [news-main] section.featured .swiper-container .swiper-wrapper .swiper-slide .slider-item {
    padding-right: 1.5rem;
  }
  [news-main] section.featured .swiper-container .swiper-wrapper .swiper-slide .slider-item .inner p {
    font-size: 4.625rem;
    line-height: 0.061875rem;
    line-height: 0.99;
  }
  [news-main] section.featured .swiper-navigation {
    right: 3rem;
    top: 0rem;
    left: auto;
    bottom: auto;
  }
  [news-main] section.featured .swiper-navigation a {
    width: 2rem;
    height: 2rem;
  }
  [news-main] section.featured .swiper-navigation .swiper-button-prev {
    margin-right: 1.5rem;
  }
  [news-main] section.latest {
    margin-top: 10rem;
    padding-left: 3rem;
    padding-right: 3rem;
  }
  [news-main] section.latest ul {
    column-gap: 24px;
  }
  [news-main] section.latest ul li {
    margin-bottom: 5.25rem;
  }
  [news-main] section.latest .more-btn {
    margin-top: 6.9375rem;
  }
  [news-main] section.latest .more-btn p {
    font-size: 1.5rem;
  }
  [news-main] section.latest .more-btn p.icon {
    width: 1.75rem;
    height: 1.75rem;
    margin-left: 0.75rem;
  }
  [news-main] section.categories {
    margin-top: 11.25rem;
    padding-left: 3rem;
    padding-right: 3rem;
  }
  [news-main] section.categories h3 {
    padding-bottom: 3.75rem;
  }
  [news-main] section.categories ul li {
    margin-right: 5rem;
  }
  [news-main] section.categories ul li a {
    font-size: 7.8125rem;
    line-height: 9.0625rem;
    
  }
}
@media (min-width: 1600px) {
  [news-main] {
    padding-bottom: 17.5rem;
  }
  [news-main] section h3 {
    font-size: 2.25rem;
  }
  [news-main] section.hero {
    padding-left: 3.75rem;
    padding-right: 3.75rem;
  }
  [news-main] section.hero .info {
    padding: 3.75rem;
    width: 68.75rem;
    left: 3.75rem;
  }
  [news-main] section.hero .info p {
    font-size: 1rem;
    padding-bottom: 0.875rem;
  }
  [news-main] section.hero .info h2 {
    font-size: 2.75rem;
    line-height: 0.09375rem;
    letter-spacing: -0.015625rem;
    line-height: 1.5;
  }
  [news-main] section.featured {
    padding-left: 3.75rem;
    padding-right: 3.75rem;
    margin-top: 11.25rem;
  }
  [news-main] section.featured h3 {
    padding-bottom: 3rem;
  }
  [news-main] section.featured .swiper-container .swiper-wrapper .swiper-slide {
    width: 46.1875rem;
    height: 37.9375rem;
  }
  [news-main] section.featured .swiper-container .swiper-wrapper .swiper-slide .slider-item {
    padding-right: 1.6875rem;
  }
  [news-main] section.featured .swiper-container .swiper-wrapper .swiper-slide .slider-item .inner p {
    font-size: 5.25rem;
    line-height: 0.061875rem;
    line-height: 0.99;
  }
  [news-main] section.featured .swiper-navigation {
    right: 3.75rem;
  }
  [news-main] section.featured .swiper-navigation a {
    width: 2.625rem;
    height: 2.625rem;
  }
  [news-main] section.featured .swiper-navigation .swiper-button-prev {
    margin-right: 2rem;
  }
  [news-main] section.latest {
    margin-top: 11.25rem;
    padding-left: 3.75rem;
    padding-right: 3.75rem;
  }
  [news-main] section.latest h3 {
    padding-bottom: 3rem;
  }
  [news-main] section.latest ul {
    column-gap: 33px;
  }
  [news-main] section.latest ul li {
    margin-bottom: 5.25rem;
  }
  [news-main] section.latest ul li p:first-of-type {
    font-size: 1rem;
    padding-bottom: 0.8125rem;
  }
  [news-main] section.latest ul li p:first-of-type span {
    margin-left: 1rem;
  }
  [news-main] section.latest ul li p:last-of-type {
    font-size: 1.5rem;
    line-height: 0.09875rem;
    letter-spacing: -0.015625rem;
    line-height: 1.58;
  }
  [news-main] section.latest .more-btn {
    margin-top: 8.75rem;
  }
  [news-main] section.latest .more-btn p {
    font-size: 1.875rem;
  }
  [news-main] section.latest .more-btn p.icon {
    width: 2rem;
    height: 2rem;
    margin-left: 1.375rem;
  }
  [news-main] section.categories {
    margin-top: 12.5rem;
    padding-left: 3.75rem;
    padding-right: 3.75rem;
  }
  [news-main] section.categories h3 {
    padding-bottom: 4.5rem;
  }
  [news-main] section.categories ul li {
    margin-right: 6.5rem;
  }
  [news-main] section.categories ul li a {
    font-size: 10.4375rem;
    line-height: 12.125rem;
    -webkit-text-stroke: 1.3px #1e1e1e;
  }
}
@media (min-width: 1921px) {
  [news-main] section.featured .swiper-container .swiper-wrapper .swiper-slide {
    width: 59.6875rem;
    height: 49.0625rem;
  }
  [news-main] section.featured .swiper-container .swiper-wrapper .swiper-slide .slider-item {
    padding-right: 2.125rem;
  }
  [news-main] section.featured .swiper-navigation a {
    width: 2.625rem;
    height: 2.625rem;
  }
  [news-main] section.featured .swiper-navigation .swiper-button-prev {
    margin-right: 2rem;
  }
  [news-main] section.categories ul li {
    margin-right: 7.5rem;
  }
}
